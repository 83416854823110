






































import {
  Component,
  PropSync,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import _ from 'lodash';

import * as Rules from '@/utils/CustomFormRules';
import { VForm } from '@/types/v-form';

import Employee from '@modules/declaration/entities/Employee';

const firmUpdateModule = namespace('firmUpdate');

interface FormLeavingEmployee {
  selectedEmployeeUuid: string | null;
  beginDate: Date | null;
  endDate: Date | null;
}

@Component
export default class SelectEmployeeModal extends Vue {
  @PropSync('show', { type: Boolean, required: true })
  visible!: Boolean;

  @State(state => state.UserModule.currentFirmId)
  readonly firmId!: number;

  @firmUpdateModule.Getter('getActiveEmployees')
  readonly employees!: (id: number) => Array<Employee>;

  @firmUpdateModule.Action('updateEmployee')
  readonly updateEmployee!: (employee: Employee) => Promise<Employee>;

  @firmUpdateModule.Getter('getEmployeeWithPerson')
  readonly getEmployee!: (uuid: string) => Employee;

  $refs!: {
    form: VForm
  };

  form: FormLeavingEmployee = {
    selectedEmployeeUuid: null,
    beginDate: null,
    endDate: null,
  };

  get rules(): Record<string, any> {
    return {
      selectedEmployeeUuid: [
        { required: true, trigger: 'blur', message: this.$t('form.required_field') },
      ],
      endDate: [
        {
          required: true,
          type: 'date',
          validator: Rules.dateGreaterThan,
          message: this.$t('rules.firm_date_greater'),
          date: this.form.beginDate,
          trigger: 'blur',
        },
      ],
    };
  }

  get filteredEmployees(): Array<Employee> {
    return _.orderBy(this.employees(this.firmId), ['label'], ['asc']);
  }

  get selectedEmployee(): Employee | null {
    return this.form.selectedEmployeeUuid ? this.getEmployee(this.form.selectedEmployeeUuid) : null;
  }

  @Watch('selectedEmployee')
  setBeginDate() {
    this.form.beginDate = this.selectedEmployee ? this.selectedEmployee.beginDate : null;
  }

  close() {
    this.$emit('update:show', false);
  }

  valid() {
    let validForm = false;
    this.$refs.form.validate((valid) => {
      validForm = valid;
    });

    if (validForm) {
      const employee = this.selectedEmployee;
      if (employee) {
        this.$emit('valid', this.form);
        employee.endDate = this.form.endDate;
        this.updateEmployee(employee);
      }
    }

    this.close();
  }
}
